<template>
  <v-dialog v-model="dialog" max-width="500px">
  <v-card class="elevation-12">
    <v-card-title>{{ note.note_title }}</v-card-title>
    <v-card-subtitle>{{ note.note_datetime }}</v-card-subtitle>
    <v-card-text style="white-space:pre-wrap;">
      <p class="body-1">{{ note.note }}</p>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="closeDialog()">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
// import Swal from "sweetalert2";

export default {
  props: {
    cid: {
      required: true,
    },
    cnid: {
      required: true,
    },
  },
  data() {
    return {
      
    };
  },
  created(){
    this.showDialog()
    console.log('note',this.note);
  },
  mounted() {
    
  },
  methods: {
    
  },
  computed: {
    note() {
      return this.$store.state.notes[this.cid][this.cnid];
    },
  },
  mixins: [dialogMixin],
};
</script>